<template>
  <div>
    <!-- <div @click="mitter">Click</div>
    <div v-show="isMitt1">Mitt1Res</div> -->

    <!-- <AddEditVehicleScreen /> -->
    <TaskList
      :fstId="`vehicleTasks_${getId}`"
      :endpoint="getEndpoint"
      :qso="getEndpointOptions"
      :hideColumns="columns"
    />
  </div>
</template>

<script>
import { TaskList } from '@/composites/task'
// import AddEditVehicleScreen from '@/components/pages/vehicle/add-edit/AddEditVehicleScreen.vue'
import { useEndpoints } from '@/composables'

export default {
  name: 'ViewVehicleTasks',
  components: {
    TaskList,
  },
  data() {
    return {
      vehicle: {},
      isMitt1: false,
      columns: {
        vehicle: true,
      },
    }
  },
  computed: {
    getId() {
      return this.$route.params.id
    },
    getEndpoint() {
      return useEndpoints.task.index()
    },
    getEndpointOptions() {
      return {
        append: `&vehicle_tag=${this.$route.params.id}`,
        prepend: `?`,
      }
    },
  },
  async created() {
    this.$mitt.on('mitt-1', () => {
      this.isMitt1 = !this.isMitt1
    })
  },
  methods: {
    mitter() {
      dispatchEvent(new Event('sor-open-scooter-single'))
      this.$mitt.emit('mitt-1')
    },
  },
}
</script>

<style lang="scss"></style>
