<script>
import {
  defineComponent,
  watch,
  toRefs,
  computed,
  ref,
} from '@vue/composition-api'

export default defineComponent({
  name: 'TaskDetailsStatusDropdown',

  props: {
    value: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [
        {
          text: 'Todo',
          value: 'T',
          style: { bgColor: '#EFF8FF', color: '#000' },
        },
        {
          text: 'In Progress',
          value: 'I',
          style: { bgColor: '#FFB740', color: '#000' },
        },
        {
          text: 'Done',
          value: 'D',
          style: { bgColor: '#4BD963', color: '#fff' },
        },
      ],
    },

    shape: {
      type: [String, Boolean],
      default: 'outline',
    },
  },

  setup(props) {
    const model = ref()
    const { value: valueRef, options: optionsRef } = toRefs(props)
    watch(valueRef, (newValue) => (model.value = newValue), {
      immediate: true,
      deep: true,
    })

    const selectedOptionCom = computed(() => {
      return (
        optionsRef.value.find(({ value }) => value === model.value) || {
          text: '--',
        }
      )
    })

    const buttonStyleCom = computed(() => {
      const style = selectedOptionCom.value?.style
      if (!style) return ''
      return `background-color: ${style.bgColor} !important; color: ${style.color} !important; `
    })

    const dropdownRef = ref(null)
    function onSelect(option) {
      model.value = option.value
      //   console.log(dropdownRef)
      dropdownRef.value.doHide()
    }

    return {
      model,
      onSelect,
      optionsRef,
      dropdownRef,
      buttonStyleCom,
      selectedOptionCom,
    }
  },
})
</script>

<template>
  <div class="w-36">
    <TDropdown
      ref="dropdownRef"
      v-model="model"
      :classes="{
        dropdown: 'origin-top-right mt-px right-0 w-36 bg-white shadow',
      }"
    >
      <template
        #trigger="{
            mousedownHandler,
            focusHandler,
            blurHandler,
            keydownHandler,
          }"
      >
        <button
          aria-label="Task Status Dropdown"
          aria-haspopup="true"
          class="flex gap-3 items-center px-3 h-10 text-sm font-medium text-gray-500 bg-gray-100 rounded border border-gray-100 cursor-pointer select-none hover:bg-gray-50 group app-form-reset"
          :style="buttonStyleCom"
          @mousedown="mousedownHandler"
          @focus="focusHandler"
          @blur="blurHandler"
          @keydown="keydownHandler"
        >
          <i class="text-sm fa fa-chevron-down" />

          {{ selectedOptionCom.text }}
        </button>
      </template>

      <div class="overflow-y-auto py-1 max-h-44 sb-farhan-light">
        <div v-for="(item, itemIndex) in optionsRef" :key="itemIndex">
          <div
            class="flex justify-between items-center py-2 px-3 w-full h-10 text-sm text-gray-600 cursor-pointer hover:bg-gray-50"
            @click="onSelect(item)"
          >
            <div class="flex gap-2 items-center">
              <!-- <span
                  v-if="shapeEnabled"
                  class="w-2 h-2 bg-gray-500 rounded-full"
                  :style="`background-color: ${item.style.bgColor};`"
                /> -->

              <span>{{ item.text }}</span>
            </div>

            <span
              v-if="item.value === model"
              class="flex justify-center items-center w-4 h-4 rounded-full border"
            >
              <i
                class="tracking-tighter leading-none text-gray-400 fas fa-check"
                style="font-size: 8px;"
              >
              </i>
            </span>
          </div>
        </div>
      </div>
    </TDropdown>
  </div>
</template>
