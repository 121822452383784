<script>
import {
  defineComponent,
  watch,
  toRefs,
  computed,
  ref,
} from '@vue/composition-api'
import { taskStatusOptions } from './helpers'

// import XStatus from '@/components/badge/XStatus'

export default defineComponent({
  name: 'AddEditStatusDropdown',

  components: {
    // XStatus,
  },

  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    hideError: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => taskStatusOptions,
    },
    shape: {
      type: [String, Boolean],
      default: 'outline',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'default',
      validator: (v) => ['default', 'badge'].includes(v),
    },
    haveSmallHeight: {
      type: Boolean,
      default: false,
    },
    haveBorder: {
      type: Boolean,
      default: true,
    },
    haveFill: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getStyle() {
      let styleStr = ''
      if (this.haveBorder) {
        styleStr += 'border-width: 1px ;'
        return styleStr
      } else {
        styleStr += 'border: none;'
        if (this.haveFill) {
          styleStr += `background-color: ${this.selectedOptionCom.style.color}`
        }
        return styleStr
      }
    },
    getHoverClass() {
      if (this.selectedOptionCom.value === 'TODO') {
        return 'todo'
      }
      if (this.selectedOptionCom.value === 'PICKED') {
        return 'pick'
      }
      if (this.selectedOptionCom.value === 'DROPPED') {
        return 'done'
      }
      if (this.selectedOptionCom.value === 'CANCELLED') {
        return 'cancel'
      }
    },
  },
  setup(props, { emit }) {
    const model = ref()
    const { value: valueRef, options: optionsRef } = toRefs(props)
    console.log('valueRef', valueRef, 'optionsRef', optionsRef)
    watch(valueRef, (newValue) => (model.value = newValue), {
      immediate: true,
      deep: true,
    })

    const selectedOptionCom = computed(() => {
      return (
        optionsRef.value.find(({ value }) => value === model.value) || {
          text: '--',
          style: { color: '#EFF8FF' },
        }
      )
    })
    const selectedOptionsComPosition = computed(() => {
      // L->Low, M->Medium,H-> High
      const isPriority = ['L', 'M', 'H'].includes(valueRef.value)
      if (isPriority) {
        return 0
      } else {
        return optionsRef.value.findIndex(
          (obj) => obj.statusText === selectedOptionCom.value.statusText
        )
      }
    })

    // todo: generate styles for text & shape based on prop
    const buttonStyleCom = computed(() => {
      const style = selectedOptionCom.value?.style
      if (!style) return ''
      return `background-color: ${style.color} !important; color: ${style.color} !important; `
    })

    const dropdownRef = ref(null)
    function onSelect(option) {
      const oldValue = model.value
      model.value = option.value
      emit('input', model.value, oldValue)
      emit('change', model.value, oldValue)
      dropdownRef.value.doHide()
      // console.log(dropdownRef)
    }

    const shouldShowDropdownCom = computed(() => {
      const hidable = ['CANCELLED', 'DROPPED']
      return !hidable.includes(model.value)
    })

    return {
      model,
      onSelect,
      optionsRef,
      dropdownRef,
      buttonStyleCom,
      selectedOptionCom,
      selectedOptionsComPosition,
      shouldShowDropdownCom,
    }
  },
})
</script>

<template>
  <section>
    <AppFormLabel :text="label" :error="error" />

    <TDropdown
      ref="dropdownRef"
      v-model="model"
      :classes="{
        dropdown: 'origin-top-right mt-px right-0 bg-white shadow w-full',
      }"
    >
      <template
        #trigger="{
              mousedownHandler,
              focusHandler,
              blurHandler,
              keydownHandler,
            }"
      >
        <button
          aria-label="Task Status Dropdown"
          aria-haspopup="true"
          :class="
            `${
              variant === 'default'
                ? `${
                    haveSmallHeight ? 'status-button h-5' : 'status-button h-10'
                  } app-form-reset `
                : 'badge-button app-form-reset'
            } ${haveFill && getHoverClass} ${!haveFill &&
              !disabled &&
              'is-editable'}`
          "
          :style="getStyle"
          :disabled="$acl.canNotUpdate('tasks')"
          @mousedown="mousedownHandler"
          @focus="focusHandler"
          @blur="blurHandler"
          @keydown="keydownHandler"
        >
          <template v-if="variant === 'default'">
            <div v-if="!haveFill" class="flex gap-2 items-center">
              <span
                v-if="shape === 'outline'"
                class="w-2 h-2 rounded-full"
                :style="
                  `border: 1px solid ${selectedOptionCom.style.color}; background-color:${selectedOptionCom.style.color}`
                "
              />
              <span
                :style="`color: rgb(107, 114 ,128);`"
                v-text="selectedOptionCom.statusText"
              />
            </div>
            <div v-if="haveFill" class="flex gap-2 items-center">
              <span
                :style="`color: ${selectedOptionCom.style.textColor};`"
                v-text="selectedOptionCom.statusText"
              />
            </div>

            <i
              v-if="!disabled"
              class="text-sm fa fa-chevron-down pl-2"
              :style="`${haveFill && 'color:#FFFFFF'}`"
            />
          </template>

          <template v-else>
            <div
              class="flex gap-2 justify-between items-center px-2 w-full text-white rounded divide-x divide-white"
              :style="`background-color: ${selectedOptionCom.style.color}`"
            >
              <p class="w-full text-left">
                {{ selectedOptionCom.statusText }}
              </p>

              <div class="pl-1.5" v-if="!disabled">
                <i class="text-sm fa fa-chevron-down" />
              </div>
            </div>
          </template>
        </button>
      </template>

      <div
        v-if="!disabled"
        class="overflow-y-auto py-1 max-h-44 sb-farhan-light"
      >
        <div v-for="(item, itemIndex) in optionsRef" :key="itemIndex">
          <div
            class="flex justify-between items-center py-2 px-3 w-full h-10 text-sm text-gray-600 cursor-pointer hover:bg-gray-50"
            @click="onSelect(item)"
            v-if="itemIndex >= selectedOptionsComPosition"
          >
            <div class="flex gap-2 items-center">
              <span
                v-if="shape === 'outline'"
                class="w-2 h-2 rounded-full"
                :style="
                  `border: 1px solid ${item.style.color}; background-color:${item.style.color}`
                "
              />

              <span>{{ item.text }}</span>
            </div>

            <span
              v-if="item.value === model"
              class="flex justify-center items-center w-4 h-4 rounded-full border"
            >
              <i
                class="tracking-tighter leading-none text-gray-400 fas fa-check"
                style="font-size: 8px;"
              >
              </i>
            </span>
          </div>
        </div>
      </div>
    </TDropdown>

    <AppFormError v-if="!hideError" :text="error" />
  </section>
</template>

<style lang="scss" scoped>
.status-button {
  @apply flex justify-between items-center px-3 text-sm font-medium text-gray-500  rounded border border-gray-400 cursor-pointer select-none hover:border-gray-500 focus:border-gray-500;
}

.badge-button {
  @apply flex justify-between items-center;
}
.todo {
  &:hover {
    background-color: rgb(29, 78, 216) !important;
  }
}

.pick {
  &:hover {
    background-color: rgb(202, 138, 4) !important;
  }
}

.done {
  &:hover {
    background-color: rgb(21, 128, 61) !important;
  }
}

.cancel {
  &:hover {
    background-color: rgb(185, 28, 28) !important;
  }
}
.is-editable {
  &:hover {
    background-color: rgb(229, 231, 235) !important;
  }
}
</style>
