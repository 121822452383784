<template>
  <div>
    <AppFormLabel :text="`Notes`" />

    <QuillEditor
      v-if="$acl.canUpdate('tasks')"
      v-model="content"
      :placeholder="'Add notes..'"
      @save="onAddNote($event, contentTypeInstanceId)"
    />

    <div class="flex justify-between items-center mt-8 mb-2">
      <div class="flex gap-3 items-center">
        <i class="text-sm fas fa-comment-alt"></i>
        <span class="font-bold text-14px">Latest Notes</span>
      </div>
    </div>

    <template v-if="notes.length > 0">
      <NoteItem
        v-for="note in notes"
        :key="note.id"
        :data="note"
        :deletePermission="$acl.canDelete('tasks')"
        @delete="onDeleteNote($event, contentTypeInstanceId)"
      />
    </template>

    <template v-else>
      <p class="mt-5 text-sm text-gray-500">No notes added yet!</p>
    </template>
  </div>
</template>

<script>
import { useEndpoints } from '@/composables'

import QuillEditor from '@/components/form/QuillEditor.vue'
import NoteItem from '@/composites/trip/details/blocks/note/NoteItem.vue'

export default {
  name: 'Notes',

  components: {
    NoteItem,
    QuillEditor,
  },

  props: {
    contentTypeInstanceId: {
      type: String,
      required: true,
    },
    contentType: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isLoading: true,
      notes: [],
      content: '',
    }
  },

  watch: {
    contentTypeInstanceId(newVal, oldVal) {
      if (newVal !== oldVal && newVal) {
        this.fetchNotes()
      }
    },
  },

  mounted() {
    this.fetchNotes()
  },

  methods: {
    async fetchNotes() {
      this.isLoading = true

      try {
        const fetchUrl = `${useEndpoints.note.index(
          this.contentTypeInstanceId,
          this.contentType
        )}?export=true`
        const { data } = await this.$http.get(fetchUrl)
        this.notes = data.data
      } catch (err) {
        console.log(err)
      } finally {
        this.isLoading = false
      }
    },

    async onAddNote() {
      this.isLoading = true

      try {
        const fetchUrl = `${useEndpoints.note.create(
          this.contentTypeInstanceId,
          this.contentType
        )}`
        const formData = new FormData()
        formData.append('details', this.content)
        const { data } = await this.$http.post(fetchUrl, formData)
        this.notes.unshift(data)
        this.content = ''
      } catch (err) {
        console.log(err)
      } finally {
        this.isLoading = false
      }
    },

    async onDeleteNote(note) {
      this.isLoading = true

      try {
        const url = useEndpoints.note.delete(note.id)
        await this.$http.delete(url)
        const index = this.notes.findIndex((row) => row.id === note.id)
        this.notes.splice(index, 1)
      } catch (err) {
        console.log(err)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
