<script>
import { EdgeStack } from '@/components/modals'
import { getUTCAwareTime, resolveProp } from '@/utils'
import TaskAddEditStatusDropdown from './AddEditStatusDropdown.vue'
import { useEndpoints } from '@/composables'
import { httpClient } from '@/services'

import { taskStatusOptions, taskPriorityOptions } from './helpers'
import Notes from './Notes'
import TimelineGroup from '@/components/timeline-new/TimelineGroup.vue'
import InfiniteLoading from 'vue-infinite-loading'
import OtoTag from '@/composites/tag/OtoTag.vue'

export default {
  name: 'TaskDetails',

  components: {
    EdgeStack,
    TaskAddEditStatusDropdown,
    TimelineGroup,
    InfiniteLoading,
    OtoTag,
    Notes,
  },

  props: {
    esId: {
      type: String,
      default: 'taskDetails',
    },
    busy: {
      type: Boolean,
      default: false,
    },
    primaryKey: {
      required: false,
    },
    rawData: {
      type: Object,
      required: false,
    },
  },

  data: () => ({
    rawDataProxy: {},

    taskStatusReq: false,
    taskPriorityReq: false,

    taskStatusOptions: taskStatusOptions,
    taskPriorityOptions: taskPriorityOptions,

    logs: [],
    isFetchingLogs: true,
  }),

  computed: {
    taskStatus: {
      get() {
        return this.rawDataProxy?.task_status ?? '--'
      },
      set(val) {
        this.rawDataProxy.task_status = val
      },
    },
    taskPriority: {
      get() {
        return this.rawDataProxy?.priority ?? '--'
      },
      set(val) {
        this.rawDataProxy.priority = val
      },
    },

    getTaskStatusDisabled() {
      return ['DROPPED', 'CANCELLED'].includes(this.rawDataProxy?.task_status)
    },

    getTaskPriorityDisabled() {
      return ['DROPPED', 'CANCELLED'].includes(this.rawDataProxy?.task_status)
    },

    getLogsEndpoint() {
      const offset = this.logs.length || 0
      return (
        useEndpoints.task.logs(this.primaryKey) + `?limit=10&offset=${offset}`
      )
    },
    getVehicleId() {
      return this.rawDataProxy?.vehicle?.id || null
    },
  },

  watch: {
    rawData: {
      deep: true,
      immediate: true,
      handler(newData) {
        this.rawDataProxy = newData
      },
    },
    primaryKey: {
      deep: false,
      immediate: false,
      handler() {
        this.logs = []
        // await this.fetchLogs()
      },
    },
  },

  methods: {
    getUTCAwareTime,

    onEdgeStack({ taskId = null } = {}) {
      this.$emit(`edgestack`, { esId: 'task-addEdit', taskId })
    },

    async onChangeTaskStatus(newValue, oldValue) {
      this.taskStatusReq = true
      const url = useEndpoints.task.update(this.primaryKey)
      const data = new FormData()
      data.append('task_status', newValue)
      await httpClient
        .patch(url, data)
        .then((res) => {
          // console.log(res.data)
          this.$store.dispatch('fsTable/fetchData')
          this.$notify({
            group: 'bottomLeft',
            type: 'success',
            title: `Task Status Has Changed`,
            text: `The task is now in '${res.data.task_status}' state`,
          })
        })
        .catch((err) => {
          console.log(err)
          this.taskStatus = oldValue
          this.$notify({
            group: 'bottomLeft',
            type: 'error',
            title: `Error Occured [${err.response.status}]`,
            text:
              err.response.data.detail ??
              'Could not change task status, please try again.',
          })
        })
        .finally(() => (this.taskStatusReq = false))
    },

    async onChangeTaskPriority(newValue, oldValue) {
      this.taskPriorityReq = true
      const url = useEndpoints.task.update(this.primaryKey)
      const data = new FormData()
      data.append('priority', newValue)
      await httpClient
        .patch(url, data)
        .then((res) => {
          const priorities = {
            L: 'Low',
            M: 'Medium',
            H: 'High',
          }
          // console.log(res.data)
          this.$notify({
            group: 'bottomLeft',
            type: 'success',
            title: `Task Priority Has Changed`,
            text: `The task is now in '${priorities[res.data.priority] ??
              '--'}' priority`,
          })
        })
        .catch((err) => {
          console.log(err)
          this.taskPriority = oldValue
          this.$notify({
            group: 'bottomLeft',
            type: 'error',
            title: `Error Occured [${err.response.status}]`,
            text:
              err.response.data.detail ??
              'Could not change task status, please try again.',
          })
        })
        .finally(() => (this.taskPriorityReq = false))
    },

    taskDetails(prop, { fallbackText = '--' } = {}) {
      const task = this.rawDataProxy || this.rawData
      return resolveProp(prop, task) ?? fallbackText
    },

    async fetchLogs($state = null) {
      this.isFetchingLogs = true

      await this.$http
        .get(this.getLogsEndpoint)
        .then((res) => {
          this.logs = this.logs.concat(res.data?.data)
          console.log('fetchLogs res = ', res.data.data.length)

          if ($state) {
            if (res?.data?.meta?.count?.total > this.logs.length) {
              $state.loaded()
            } else {
              $state.complete()
            }
          }
        })
        .catch((err) => {
          console.error('fetchLogs err = ', err, err.response.data)
        })
        .finally(() => (this.isFetchingLogs = false))
    },
  },
}
</script>

<style lang="scss" scoped>
.panel-title {
  font-size: 22px;
  font-weight: 500;
  color: #2e2e39;
}

.list-item {
  display: grid;
  align-items: start;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 10px;
  padding: 10px;
  font-size: 14px;

  & .title {
    grid-column: span 1 / span 1;
    font-weight: 700;
    color: #2e2e39;
  }

  & .value {
    grid-column: span 2 / span 2;
    color: #6d6d75;
  }
}
.is-editable {
  &:hover {
    background-color: rgb(229, 231, 235) !important;
  }
}
</style>

<template>
  <EdgeStack :id="esId" :busy="busy" :size-class="`w-full md:w-3/5`">
    <template #header>
      <div class="flex justify-between -mt-8">
        <div class="panel-title">{{ taskDetails('title') }}</div>

        <AppButton
          v-if="$acl.canUpdate('tasks')"
          variant="secondary"
          text="Edit Task"
          rounded="full"
          @click="onEdgeStack({ taskId: primaryKey })"
        />
      </div>
    </template>

    <section class="px-2">
      <div class="list-item">
        <div class="title">Status</div>
        <div class="flex items-center gap-2 value">
          <div class="w-40">
            <TaskAddEditStatusDropdown
              v-model="taskStatus"
              :options="taskStatusOptions"
              :disabled="getTaskStatusDisabled"
              :haveBorder="false"
              :haveFill="true"
              hideError
              @change="onChangeTaskStatus"
            />
          </div>

          <AppButton
            variant="transparent"
            height="24px"
            text=""
            rounded="sm"
            :is-loading="taskStatusReq"
          />
        </div>
      </div>

      <div class="list-item">
        <div class="title">Priority</div>
        <div class="flex items-center gap-2 value">
          <div class="w-40">
            <TaskAddEditStatusDropdown
              v-model="taskPriority"
              :options="taskPriorityOptions"
              :disabled="getTaskPriorityDisabled"
              :haveBorder="false"
              hideError
              @change="onChangeTaskPriority"
            />
          </div>
          <AppButton
            variant="transparent"
            height="24px"
            text=""
            rounded="sm"
            :is-loading="taskPriorityReq"
          />
        </div>
      </div>

      <div class="list-item">
        <div class="title">Description</div>
        <div class="value">
          {{ taskDetails('description') }}
        </div>
      </div>

      <div class="list-item">
        <div class="title">Created By</div>
        <div class="value">
          <div class="flex gap-4">
            <div class="w-10 h-10 bg-gray-300 rounded-full">
              <img
                :src="taskDetails('created_by.profile_pic')"
                alt="avatar"
                class="object-cover w-10 h-10 rounded-full px-1 py-3 text-xs"
              />
            </div>
            <div>
              <div>{{ taskDetails('created_by.full_name') }}</div>
              <div class="text-xs">
                {{ taskDetails('created_by.organization_role') }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="list-item">
        <div class="title">Assigned To</div>
        <div class="value ">
          <div class="flex w-5/6 gap-4 py-1 rounded">
            <div class="w-10 h-10 bg-gray-300 rounded-full">
              <img
                :src="taskDetails('user.profile_pic')"
                alt="avatar"
                class="object-cover w-10 h-10 rounded-full px-1 py-3 text-xs"
              />
            </div>

            <div>
              <div>{{ taskDetails('user.full_name') }}</div>
              <div class="text-xs">
                {{ taskDetails('user.organization_role') }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="list-item">
        <div class="title">Task Type</div>
        <div class="value">
          {{ taskDetails('task_type') }}
        </div>
      </div>

      <div class="list-item">
        <div class="title">Date Created</div>
        <div class="value">
          <span
            v-text="getUTCAwareTime(taskDetails('created_at'))"
            v-tooltip="
              getUTCAwareTime(taskDetails('created_at'), { relative: true })
            "
          />
        </div>
      </div>

      <div class="list-item">
        <div class="title">Last Updated</div>
        <div class="value">
          <span
            v-text="
              getUTCAwareTime(taskDetails('updated_at'), { relative: true })
            "
            v-tooltip="getUTCAwareTime(taskDetails('updated_at'))"
          />
        </div>
      </div>

      <div class="list-item">
        <div class="title">Due At</div>
        <div class="value">
          <span
            v-text="getUTCAwareTime(taskDetails('due_by'), { relative: true })"
            v-tooltip="getUTCAwareTime(taskDetails('due_by'))"
          />
        </div>
      </div>

      <div class="list-item">
        <div class="title">Vehicle QRCode</div>
        <div class="value">#{{ taskDetails('vehicle.qr_code') }}</div>
      </div>

      <div class="list-item">
        <div class="title">Tags</div>
        <div class="value">
          <OtoTag
            :entity-id="getVehicleId"
            :entity-type="'bike'"
            :editPermissionStatus="$acl.canNotUpdate('tasks')"
          />
        </div>
      </div>

      <div class="my-2 p-3">
        <div class="value">
          <notes
            content-type="bike"
            :content-type-instance-id="getVehicleId"
            v-if="getVehicleId !== null && getVehicleId !== ''"
          />
        </div>
      </div>

      <div class="my-2 mt-4 border-t-2" />

      <TimelineGroup :logs="logs" :isLoading="isFetchingLogs" />
      <InfiniteLoading @infinite="fetchLogs" />
    </section>
  </EdgeStack>
</template>
