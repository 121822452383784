var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-36"},[_c('TDropdown',{ref:"dropdownRef",attrs:{"classes":{
      dropdown: 'origin-top-right mt-px right-0 w-36 bg-white shadow',
    }},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
        var mousedownHandler = ref.mousedownHandler;
        var focusHandler = ref.focusHandler;
        var blurHandler = ref.blurHandler;
        var keydownHandler = ref.keydownHandler;
return [_c('button',{staticClass:"flex gap-3 items-center px-3 h-10 text-sm font-medium text-gray-500 bg-gray-100 rounded border border-gray-100 cursor-pointer select-none hover:bg-gray-50 group app-form-reset",style:(_vm.buttonStyleCom),attrs:{"aria-label":"Task Status Dropdown","aria-haspopup":"true"},on:{"mousedown":mousedownHandler,"focus":focusHandler,"blur":blurHandler,"keydown":keydownHandler}},[_c('i',{staticClass:"text-sm fa fa-chevron-down"}),_vm._v(" "+_vm._s(_vm.selectedOptionCom.text)+" ")])]}}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_c('div',{staticClass:"overflow-y-auto py-1 max-h-44 sb-farhan-light"},_vm._l((_vm.optionsRef),function(item,itemIndex){return _c('div',{key:itemIndex},[_c('div',{staticClass:"flex justify-between items-center py-2 px-3 w-full h-10 text-sm text-gray-600 cursor-pointer hover:bg-gray-50",on:{"click":function($event){return _vm.onSelect(item)}}},[_c('div',{staticClass:"flex gap-2 items-center"},[_c('span',[_vm._v(_vm._s(item.text))])]),(item.value === _vm.model)?_c('span',{staticClass:"flex justify-center items-center w-4 h-4 rounded-full border"},[_c('i',{staticClass:"tracking-tighter leading-none text-gray-400 fas fa-check",staticStyle:{"font-size":"8px"}})]):_vm._e()])])}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }