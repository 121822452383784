var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-3 md:mt-0"},[_c('t-dropdown',{attrs:{"variant":"smActions"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
      var mousedownHandler = ref.mousedownHandler;
      var focusHandler = ref.focusHandler;
      var blurHandler = ref.blurHandler;
      var keydownHandler = ref.keydownHandler;
return [_c('button',{staticClass:"text-oDark focus:outline-none",attrs:{"aria-label":"Task Status Menu","aria-haspopup":"true"},on:{"mousedown":mousedownHandler,"focus":focusHandler,"blur":blurHandler,"keydown":keydownHandler}},[_c('i',{staticClass:"text-sm text-gray-600 fas fa-ellipsis-v"})])]}},{key:"default",fn:function(ref){
      var hide = ref.hide;
return [_c('div',{staticClass:"py-1 mt-px bg-white rounded-md shadow-md z-200",on:{"click":function($event){return hide()}}},[_c('t-dropdown-item',{attrs:{"variant":"danger"},on:{"click":_vm.askConfirmation}},[_vm._v(" Delete ")])],1)]}}])}),_c('ConfirmFirst',{key:("confirmFirst-" + (_vm.data.id)),attrs:{"id":("confirmFirst-" + (_vm.data.id)),"isLoading":_vm.isLoading,"title":"Delete Task?","subtitle":"All associated data will be deleted. This action cannot be undone.","criticalityLevel":"medium","criticalityTitle":"Please Type The Delation Code","criticalityPassword":"ILOVEOTORIDE","confirm-button-text":"Delete","confirm-button-variant":"warning-alt"},on:{"confirm":function($event){return _vm.executeAction('delete')},"cancel":_vm.handleCancellation}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }